<template>
  <div>
    <app-card card-class="h-auto">
      <template #body>
        <div class="d-flex">
          <app-datepicker
              v-for="({ name, label, placeholder }, index) in datePickers"
              :key="index"
              class="w-50"
              :class="{'ml-5': index}"
              :label="$t(label)"
              :placeholder="$t(placeholder)"
              :value="form[name]"
              @change="form[name] = $event"
          />
        </div>
        <b-button variant="primary" :disabled="isSearchDisabled" @click="exportAction">{{ $t('btn.export') }}</b-button>
      </template>
    </app-card>
  </div>
</template>
<script>
import AppCard from "@/components/AppCard.vue";
import AppDatepicker from "@/components/form-groups/AppDatepicker.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    AppCard,
    AppDatepicker
  },
  data: () => ({
    datePickers: [
      {name: 'start_date', label: 'label.start_date', placeholder: 'placeholder.choose_start_date'},
      {name: 'end_date', label: 'label.end_date', placeholder: 'placeholder.choose_end_date'},
    ],
    form: {start_date: null, end_date: null},
  }),
  computed: {
    ...mapGetters({
      exportFileUrl: 'locksReportStore/EXPORT_FILE_URL'
    }),
    isSearchDisabled() {
      return !this.form.start_date || !this.form.end_date;
    }
  },
  watch: {
    exportFileUrl(value) {
      if (value) {
        const a = document.createElement('a');
        a.href = value;
        a.setAttribute('download', ''); // Or set a specific filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  },
  methods: {
    exportAction() {
      this.$store.dispatch('locksReportStore/GET_EXPORT_FILE', this.form);
    }
  }
}
</script>